@import '../../style/abstracts/_abstracts-dir';

.lg-video-form {
  > div:not(:last-child) {
    padding-bottom: 16px;
  }

  .lg-video-form--btn {
    margin-right: 5px !important;

    .lg-video-form--btn-label {
      @include setFont($font-size--md, $font-weight--regular);
      text-transform: none;
    }
  }
}

.MuiDialog-paperWidthSm {
  max-width: 460px !important;
}

.edit-dialog-box--btn{
  margin-right: 5px !important;
}

@media only screen and (min-width: 200px) and (max-width: 400px) {
  .MuiDialog-paperFullWidth {
    width: 100% !important;
    margin: 10px !important;
  }

  .lg-video-form--btn {
    width: 48% !important;
    margin-right: 5px !important;
  }
}

.is--private{
  padding-bottom: 0px !important;
}

.lg-video-form{
  margin-bottom: 0 !important;
}

.lg-select--menu .lg-select--paper .lg-select--list .lg-select--item{
  margin-left: 10px !important;
}