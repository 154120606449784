@import '../../style/abstracts/_abstracts-dir';

body {
  font-family: "Arial", sans-serif !important;
}

.MuiOutlinedInput-inputMultiline, .MuiSelect-select, .MuiInputLabel-outlined, .MuiTab-root {
  font-family: "Arial", sans-serif !important;
}

.tool__bar-container {
  width: 100% !important;
  text-align: center !important;
}

.tool__bar {
  display: inline-block !important;
  //width: 100% !important;
  padding: 0 !important;
  padding-left: 5px !important;
}

.lg-home {
  //padding: 16px;

  .lg-home--actions {
    margin-top: 63px;
    background-color: #D3D8DC;
    z-index: 1099;
    box-shadow: none;

    .lg-home--toolbar {
      display: flex;
      flex-direction: row-reverse;
      height: 72px;
    }
  }

  .lg-home--posts {
    margin-top: 64px;
  }
}

.tab__item {
  display: inline-block !important;
}

@media only screen and (min-width: 300px) and (max-width: 400px) {
  .tab__item span {
    font-size: 11px;
  }
}

@media only screen and (min-width: 200px) and (max-width: 300px) {
  .tab__item span {
    font-size: 9px !important;
  }
}