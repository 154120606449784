
.footer-item-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: auto !important;

  @media only screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
}

.footer-list-item-contaienr.MuiListItem-gutters {
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 768px) {
    flex: 0 0 100%;
  }
}
.footer-list-item-contaienr a {
  transition: all 0.4s;
  transition-duration: 1;
}

.footer-list-item-contaienr a:hover {
  transform: translateY(-5px);
}



.footer-list-item-contaienr .makeStyles-block-52 {
  @media only screen and (max-width: 768px) {
    padding: 1px;
  }
}

.footer-container {
  padding-top: 40px;
  background-color: #d3d8dc;
  &__image {
    height: 95px;
    width: 137px;
    margin-left: -20px;

    @media only screen and (max-width: 768px) {
      margin-left: 0;
    }
  }

  &__desc {
    color: black;
    width: 280px;
    font-family: "Arial", sans-serif !important;
    margin-right: -100;
    margin-top: -25px;
    text-align: left;
    font-size: 15px;
    line-height: 34px;
    padding-bottom: 10px;
  }

  &__list-title {
    font-weight: 700;
    color: #3C77A2;
    margin-left: 20px;
  }

  &__list-items {
    margin-top: 10px;
    margin-left: 20px;
    &__title {
      font-family: "Arial", sans-serif !important;
      font-size: 18px;
      color: black;
      text-decoration: none;
      a {
        font-family: "Arial", sans-serif !important;
        font-size: 18px;
        color: black;
        text-decoration: none;
      }
    }

    &__number {
      color: black;
      font-size: 26px;
    }

    &__email {
      font-size: 16px;
      white-space: pre-wrap;
      width: 250px;
      color: black;
      word-break: break-all;
    }

    &__social-container {
      display: flex;
      align-items: center;
      margin-top: 30px;

      &-icon {
        border: 1px solid #4797bb;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        padding: 10px;
        cursor: pointer;
        transition: all 0.4s;
        &:hover {
          background: #4797bb;
          color: white;
        }
      }
    }
  }

  &__border {
    width: 100%;
    border: 1px solid #4797bb;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  &__copy-right {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: #4797bb;
    margin-bottom: 30px;
  }

  &__consulting-text {
    text-decoration: none;
    color: #4797bf;
    font-weight: 700;
  }
}

.social-icon-right {
  margin-right: 15px;
}

/*basic footer*/
.footer-basic {
  padding:40px 0;
  //background-color:#ffffff;
  color:#4b4c4d;
  background-color: #d3d8dc;
}

.footer-basic ul {
  padding:0;
  list-style:none;
  text-align:center;
  font-size:18px;
  line-height:1.6;
  margin-bottom:0;
}

.footer-basic li {
  padding:0 10px;
}

.footer-basic ul a {
  color:inherit;
  text-decoration:none;
  opacity:0.8;
}

.footer-basic ul a:hover {
  opacity:1;
}

.tpl__platform-link{
  color:inherit;
  text-decoration:none;
  opacity:0.8;
}
.tpl__platform-link:hover {
  opacity:1;
}

.footer-basic .social {
  text-align:center;
  padding-bottom:25px;
}

.footer-basic .social > a {
  font-size:24px;
  width:40px;
  height:40px;
  line-height:40px;
  display:inline-block;
  text-align:center;
  border-radius:50%;
  border:1px solid #ccc;
  margin:0 8px;
  color:inherit;
  opacity:0.75;
}

.footer-basic .social > a:hover {
  opacity:0.9;
}

.footer-basic .copyright {
  margin-top:15px;
  text-align:center;
  font-size:13px;
  //color:#aaa;
  color:#4b4c4d;
  margin-bottom:0;
}

.social__icon{
  margin-top: 7px !important;
}
