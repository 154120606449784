/* Colors */
$black: #1d1f20;
$blue: #83e4e2;
$gray: #d3d8dc;
$yellow: #fddc32;
$white: #fafafa;

.button__margin-left {
  margin-left: 10px !important;
}

.player__list-box {
  padding: 5px 10px !important;
  margin-top: 5px !important;
  margin-bottom: 10px !important;
}

.player__box {
  display: inline !important;
  margin-top: 10px;
  float: left;
}

.player__button {
  float: right;
}

.dialog-box--btn {
  margin-right: 5px !important;
}

.lg-select {
  text-align: left !important;
  margin-bottom: 10px !important;
}

.text--green {
  color: green !important;
}

.text--red {
  color: red !important;
}

button {
  margin-bottom: 5px !important;
}

@media only screen and (min-width: 300px) and (max-width: 400px) {
  .input__form-grid-container {
    text-align: center !important;
    width: 100% !important;
  }
  .input__form-grid, .payout__grid {
    margin-left: 10px !important;
    margin-right: 10px !important;
    width: 100% !important;
    max-width: 95% !important;
    flex-basis: unset !important;
  }
  .edit-dialog-box--btn, .dialog-box--btn {
    padding: 5px !important;
    min-width: unset !important;
  }
  .payout__grid-item {
    margin-right: 15px !important;
    margin-left: 10px !important;
  }
}

@media only screen and (min-width: 200px) and (max-width: 300px) {
  .input__form-grid-container {
    text-align: center !important;
    width: 100% !important;
  }
  .input__form-grid, .payout__grid {
    margin-left: 10px !important;
    margin-right: 10px !important;
    width: 100% !important;
    max-width: 95% !important;
    flex-basis: unset !important;
  }
  .edit-dialog-box--btn, .dialog-box--btn {
    padding: 5px !important;
    min-width: unset !important;
  }

  .player__button {
    margin-top: 10px !important;
    float: left !important;
    clear: both !important;
  }
  .player__list-box {
    padding-left: 5px !important;
    padding-right: 0 !important;
  }
  .payout__grid-item {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }
}

/* box-shadow */
%boxshadow {
  box-shadow: 0.25rem 0.25rem 0.6rem rgba(0,0,0,0.05), 0 0.5rem 1.125rem rgba(75,0,0,0.05);
}

ol.gradient-list {
  counter-reset: gradient-counter;
  list-style: none;
  margin: 1.75rem 0;
  padding-left: 1rem;
  > li {
    background: white;
    border-radius: 0 0.5rem 0.5rem 0.5rem;
    @extend %boxshadow;
    counter-increment: gradient-counter;
    margin-top: 1rem;
    min-height: 3rem;
    padding: 1rem 1rem 1rem 3rem;
    position: relative;
    &::before,
    &::after {
      background: linear-gradient(135deg, $blue 0%,$gray 100%);
      border-radius: 1rem 1rem 0 1rem;
      content: '';
      height: 3rem;
      left: -1rem;
      overflow: hidden;
      position: absolute;
      top: -1rem;
      width: 3rem;
    }
    &::before {
      align-items: flex-end;
      @extend %boxshadow;
      content: counter(gradient-counter);
      color: $black;
      display: flex;
      font: 600 1em 'Arial';
      justify-content: flex-end;
      padding: 0.125em 0.25em;
      z-index: 1;
    }
    @for $i from 1 through 5 {
      &:nth-child(10n+#{$i}):before {
        //background: linear-gradient(135deg, rgba($gray, $i * 0.2) 0%,rgba($yellow, $i * 0.2) 100%);
      }
    }
    @for $i from 6 through 10 {
      &:nth-child(10n+#{$i}):before {
        background: linear-gradient(135deg, rgba($gray, 1 - (($i - 5) * 0.2)) 0%,rgba($yellow, 1 - (($i - 5) * 0.2)) 100%);
      }
    }
    + li {
      margin-top: 2rem;
    }
  }
}
