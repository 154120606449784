
.player__first, .player__second {

}

.arrow--icon {
  padding-top: 5px !important;
}

.colan__span {
  margin-top: 15px !important;
}

.text--center {
  text-align: center !important;
}

.grid__container {
  text-align: center !important;
}

.score__list-table {
  width: 100% !important;
  text-align: center !important;
}